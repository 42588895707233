<template>
  <PageCard
    pageName="Technology Analytics"
    :iconClass="['cuis-file-code', 'card-icon-font']"
  >
    <template slot="page_content">
      <b-row class="about-row">
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0  pb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">FinTech Market Reports</div>
            <b-card-body class="p-0 flex-card-body">
              <div class="content-div">
                <p>
                  With FinTech Market Share reports quickly assess market share
                  rank by vendor or vendor-product combinations.
                </p>
                <p>
                  Other vendors offering your technology may not actually be
                  your true competition – if they tend to attract a different FI
                  segment. The FinTech Firmographics reports allows users to
                  quickly identify each vendor’s client composition.
                </p>
                <p>
                  Differentiated by FI Navigator’s depth of coverage, the
                  FinTech market reports areideal for strategy development,
                  competitive intelligence, segment analysis, white space
                  identification or producing marketing ammo highlighting your
                  firm’s client performance vs. the competition.
                </p>
                <b-card-img
                  src="../../../../static/img/market_reports.png"
                  class="rounded-0 my-2"
                />
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0  pb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">FIN VendorFit&reg;</div>
            <b-card-body class="p-0 flex-card-body">
              <div class="content-div">
                <p>
                  Leverage FIN VendorFit® to rank core processing vendor-product
                  fit for any U.S. financial institution. The regression
                  analysis integrates over 100 independent variables ranging
                  from asset size to technology purchase behavior.
                </p>
                <p>
                  Utilize the scoring for new sales to identify higher
                  probability conversion candidates or for account management to
                  ID your currently at-risk clients.
                </p>
                <p>
                  Compare any subject FI with the client base of any chosen
                  vendor-product leveraging presentation-ready graphics covering
                  size, market, strategy, performance, productivity, and
                  technologies.
                </p>
                <b-card-img
                  src="../../../../static/img/vendor_fit.png"
                  class="rounded-0 my-2"
                />
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0  pb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">FIN Digital Channels&reg;</div>
            <b-card-body class="p-0 flex-card-body">
              <div class="content-div">
                <p>
                  The FIN Digital Channels® solution focuses on retail mobile
                  and online banking performance delivering comprehensive data
                  and innovative metrics on vendor and FI performance.
                </p>
                <p>
                  Covering every U.S. vendor product, the solution produces
                  comparative vendor dashboards on market share, share
                  accretion, win-loss counts, churn rates, and more.
                </p>
                <p>
                  The institution dashboards cover every U.S. bank and credit
                  union offering retail online and mobile banking with
                  segmentation by FI type, asset size and other variables. FIN
                  Digital Channels delivers an unprecedented view on how retail
                  digital channels are evolving to augment your product and
                  market strategy development.
                </p>
                <b-card-img
                  src="../../../../static/img/digital_channels_mobile_banking.png"
                  class="rounded-0 my-2"
                />
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'AboutTechnologyAnalytics',
  components: {
    PageCard
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'Technology Analytics'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>

<style lang="scss" scoped>
.flex-card-body {
  display: flex;
  flex-direction: column;
  .content-div {
    flex-grow: 1;
  }
}
</style>
